import React, { useState, useEffect } from 'react'
import { Text } from 'react-native'
import { Auth } from 'aws-amplify'
import styled from 'styled-components/native'
import { Hoverable } from 'react-native-web-hover'
import { ContinueButton } from 'components/ContinueButton'
import colors from 'colors'
import { StyledTextInput } from 'components/InputField'
import { StandardContainer, FieldContainer, HeaderTextWrapper } from 'commons/containers'
import { getInitialRoute } from 'navigationActions'
import * as screenNames from 'screenNames'
import { validateEmail } from 'helpers'
import { Inter500, HeaderText, SubHeaderText, SmallText } from 'typography'
import { getUserTypeAsync } from 'actions'
import { isWeb, USER_TYPES } from '@constants'
import { FooterBar } from 'commons/components/FooterBar'
import { useDimensions } from 'dimensions'
const { TRAINEE, INSTRUCTOR, ADMIN } = USER_TYPES

const noop = () => {}
const resendTimeoutms = 30000

const ResetPasswordFields = ({
    verificationCode,
    setVerificationCode,
    setPassword,
    passwordError,
    codeError,
    onSubmitEditing,
}) => {
    return (
        <>
            <StyledTextInput
                shouldSanitizeInput
                fieldTitle={'Verification code'}
                onChangeText={setVerificationCode}
                errorMessage={codeError}
                value={verificationCode}
            />
            <StyledTextInput
                fieldTitle={'New Password'}
                onChangeText={setPassword}
                secure={true}
                errorMessage={passwordError}
                onSubmitEditing={onSubmitEditing}
            />
        </>
    )
}

const LoginScreen = ({ navigation }) => {
    const { isMobileWeb } = useDimensions()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [phoneOrEmail, setPhoneOrEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [resetError, setResetError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [codeError, setCodeError] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [justReset, setJustReset] = useState(false)
    const [displayReset, setDisplayReset] = useState(false)
    const [resendable, setResendable] = useState(true)
    const [loginLoading, setLoginLoading] = useState(false)
    const [isValid, setIsValid] = useState(false)

    let headerText = 'Log in'
    if (resetPassword) headerText = 'Reset password'
    else if (forgotPassword) headerText = 'Forgot password'

    let subHeaderText = "Don't have an account?"
    if (resetPassword && phoneOrEmail === 'EMAIL') subHeaderText = `A reset code was sent to ${email}`
    else if (resetPassword && phoneOrEmail === 'SMS') subHeaderText = `A reset code was sent to ${phoneNumber}`
    else if (forgotPassword) subHeaderText = 'Please enter the email associated with your account'

    let forgotPasswordText = forgotPassword ? 'Cancel' : 'Forgot password?'
    const goToSignUp = () => navigation.navigate(screenNames.SIGN_UP)
    async function handleLogin() {
        if (isValid) {
            try {
                setLoginLoading(true)
                await Auth.signIn(email, password)
                const { userType = TRAINEE, applicationStatus = '', submitted = false } = await getUserTypeAsync()
                const shouldAwaitInstructorNavigatorMount = (userType === INSTRUCTOR || userType === ADMIN) && isWeb
                const initialRoute = getInitialRoute({
                    userType,
                    applicationStatus,
                    submitted,
                    isPath: isMobileWeb ? false : true,
                    isMobileWeb,
                })
                if (isMobileWeb) {
                    navigation.navigate(initialRoute)
                    setLoginLoading(false)
                    clearState()
                } else {
                    setTimeout(
                        () => {
                            setLoginLoading(false)
                            clearState()
                            navigation.navigate(...initialRoute)
                        },
                        // Timeout added to ensure the INSTRUCTOR_NAVIGATOR is conditionally mounted prior to navigating away from login screen
                        shouldAwaitInstructorNavigatorMount ? 1000 : 0,
                    )
                }
            } catch (error) {
                __DEV__ && console.log('error: ', error)
                setLoginLoading(false)
                setPasswordError(
                    error?.code !== 'InvalidParameterException'
                        ? error?.message ?? 'There was an error signing in, please try again'
                        : 'There was an error signing in, please try again',
                )
            }
        }
    }

    async function handleSendResetCode() {
        if (validateEmail(email) && resendable) {
            try {
                const data = await Auth.forgotPassword(email)
                setPhoneOrEmail(data?.CodeDeliveryDetails?.DeliveryMedium)
                if (data?.CodeDeliveryDetails?.DeliveryMedium === 'SMS') {
                    setPhoneNumber(data?.CodeDeliveryDetails?.Destination)
                }
                setResetPassword(true)
                setResendable(false)
                setTimeout(() => {
                    setResendable(true)
                }, resendTimeoutms)
            } catch (error) {
                __DEV__ && console.log('Error resetting password: ', error)
                if (error?.code === 'UserNotFoundException') setResetError('An account with that email does not exist.')
            }
        } else {
            setResetError('Please provide a valid email address.')
        }
    }

    async function handleSetNewPassword() {
        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters')
        } else {
            try {
                const data = await Auth.forgotPasswordSubmit(email, verificationCode, password)
                setResetPassword(false)
                setForgotPassword(false)
                setEmail('')
                setPassword('')
                setJustReset(true)
            } catch (error) {
                if (error?.code === 'CodeMismatchException') setCodeError('Invalid verification code provided.')
                if (error?.code === 'ExpiredCodeException')
                    setCodeError('Your verification code has expired. Please try again.')
                if (error?.code === 'InvalidPasswordException') setPasswordError(error?.code.split(':')[1])
                __DEV__ && console.log('Error resetting password: ', error)
            }
        }
    }

    function handleCancel() {
        if (!forgotPassword) {
            setEmail('')
            setForgotPassword(true)
        } else {
            setResetPassword(false)
            setForgotPassword(false)
        }
    }

    const SendNewCode = () => {
        return (
            <Text>
                Didn't recieve a code?{' '}
                <Text
                    onPress={resendable ? handleSendResetCode : () => {}}
                    style={{ color: resendable ? colors.blueContinue : '' }}
                >
                    Try again
                </Text>
            </Text>
        )
    }

    const clearState = () => {
        setEmail('')
        setPassword('')
        setVerificationCode('')
        setPhoneOrEmail('')
        setPhoneNumber('')
        setResetError('')
        setPasswordError('')
        setCodeError('')
        setForgotPassword(false)
        setResetPassword(false)
        setJustReset(false)
        setDisplayReset(false)
        setResendable(true)
        setLoginLoading(false)
        setIsValid(false)
    }

    useEffect(() => {
        if (email && password?.length >= 8) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [email, password])

    useEffect(() => {
        if (justReset) {
            setDisplayReset(true)
            setTimeout(() => {
                setDisplayReset(false)
            }, 3000)
        }
    }, [justReset])

    return (
        <>
            <StandardContainer>
                <HeaderTextWrapper style={{ marginBottom: 0 }}>
                    <HeaderText>{headerText}</HeaderText>
                    {resetPassword && <SendNewCode />}
                    <SubHeaderText isSubtext style={{ marginBottom: 10 }}>
                        {subHeaderText}{' '}
                        {!forgotPassword && (
                            <SubHeaderText onPress={goToSignUp} isBold isSubtext>
                                {' '}
                                Sign up
                            </SubHeaderText>
                        )}
                    </SubHeaderText>
                    <SmallText isSubtext style={{ color: `${colors.homebodyTurquoise}` }}>
                        {displayReset ? 'Congrats! Your password has been reset' : ' '}
                    </SmallText>
                </HeaderTextWrapper>
                <FieldContainer>
                    {resetPassword && (
                        <ResetPasswordFields
                            verificationCode={verificationCode}
                            setVerificationCode={setVerificationCode}
                            setPassword={setPassword}
                            passwordError={passwordError}
                            codeError={codeError}
                            onSubmitEditing={handleSetNewPassword}
                        />
                    )}
                    {!resetPassword && (
                        <StyledTextInput
                            autoFocus
                            fieldTitle={'Email'}
                            onChangeText={setEmail}
                            errorMessage={resetError}
                            value={email}
                            onSubmitEditing={forgotPassword ? handleSendResetCode : noop}
                        />
                    )}
                    {!forgotPassword && (
                        <StyledTextInput
                            fieldTitle={'Password'}
                            onChangeText={setPassword}
                            value={password}
                            secure={true}
                            errorMessage={passwordError}
                            onSubmitEditing={handleLogin}
                        />
                    )}
                    <Hoverable>
                        {({ hovered }) => (
                            <ForgotPasswordText onPress={justReset ? noop : handleCancel} hovered={hovered}>
                                {' '}
                                {forgotPasswordText}
                            </ForgotPasswordText>
                        )}
                    </Hoverable>
                    <ContinueButton
                        actionLoading={loginLoading}
                        onPress={
                            resetPassword ? handleSetNewPassword : forgotPassword ? handleSendResetCode : handleLogin
                        }
                        style={{ marginTop: 25, width: 363, height: 56, alignSelf: isWeb ? 'flex-start' : 'center' }}
                        text="Continue"
                        color={isValid || forgotPassword ? colors.blueContinue : colors.grayContinue}
                    />
                </FieldContainer>
            </StandardContainer>
            {isWeb && !isMobileWeb && <FooterBar />}
        </>
    )
}
export const ForgotPasswordText = styled(Inter500)`
    font-size: 14.18px;
    color: ${props => (props.hovered ? colors.blueHover : colors.black)};
    line-height: 14.18px;
    margin-top: 10px;
`
export const SubTitleText = styled(Inter500)`
    font-size: 20px;
    text-align: left;
    align-self: flex-start;
    margin-vertical: 15px;
    color: ${colors.subTextGray};
`
export default LoginScreen
