/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3456c90c-174a-4bd3-964d-19f04df0ad7e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1L4ckoa0Z",
    "aws_user_pools_web_client_id": "7k6pic4itf7tpcal3i4d104dp7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://fg2j7fwacrehfkrmurncm6h76y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-25hhbzoytjb2box7q2a6hpjrka",
    "aws_user_files_s3_bucket": "homebodyprods3bucket111653-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
